function Footer()
{
    return(
        <>
            <footer>
                Ce site a été développé par <a href="https://www.clement-lores.fr" target="_blank">Clément LORES</a>
            </footer>
        </>
    )
}

export default Footer