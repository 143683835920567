import Home from './pages/home/index'
import Multiplayer from './pages/multiplayer/menu/index'
import MultiplayerGame from './pages/multiplayer/game/index'
import Header from './components/header/index'
import Footer from './components/footer/index'
import Invite from './pages/invite/index'
import ErrorPage from './pages/error/index'
import { Route, Routes, useNavigate } from 'react-router-dom';
import { useEffect } from 'react'

import io from 'socket.io-client';
const socket = io.connect(`${process.env.REACT_APP_SOCKET}`);

function App() 
{
  const navigate = useNavigate();

  useEffect(() => 
  {
    socket.on('disconnect', (reason) => {
      navigate("/error")
    });

    socket.on('connect_error', (error) => {
      navigate("/error")
    });

  }, [])

  return (
    <>
    <Header/>
    <Routes>
      <Route exact path="/" element={<Home socket={socket}/>} />
      <Route exact path="/multiplayer/:id" element={<MultiplayerGame socket={socket}/>} />
      <Route exact path="/multiplayer" element={<Multiplayer socket={socket}/>} />
      <Route exact path="/invite/:id" element={<Invite socket={socket}/>}/>
      <Route exact path="/error" element={<ErrorPage/>}/>
    </Routes>
    <Footer/>
    </>
  );
}

export default App;
